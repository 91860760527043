<template>
  <div class="main-content">
    抱歉，您没有权限访问
  </div>
</template>

<script>
import { setCurrentInfo } from "@/utils/auth";
import store from "@/store";
export default {
  beforeRouteEnter(to, from, next) {
    // console.log("store.getters.currentId: ", store.getters.currentInfo);
    /**
     * @query
     * type 标志来自院校还是企业管理 school,enterprise
     * id 来源机构的id
     * example：localhost:8212?type=school&id=123456
     */
    if (to.query && to.query.type && to.query.id) {
      setCurrentInfo(to.query);
      store.dispatch("threepart/setCurrentInfo", to.query);
      console.log(store.getters);
      next("/DCDataCentre");
    } else {
      if (store.getters.currentId) {
        next((vm) => {
          vm.$router.replace(store.getters.currentType);
        });
      } else {
        next((vm) => {
          vm.$router.replace("/custom");
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
